<template>
  <KycContainer 
    @submit="next"
    :isMobile="isMobileDevice"
  >
    <template slot="title">
      {{ $t('kyc.modal.basic_info.title') }}
    </template>

    <div>
      <div class="subtitle"> 
        {{ $t('kyc.modal.basic_info.subtitle') }}
      </div>

      <CustomInput
        v-model="form.firstName"
        class="mt-s24"
        testId="first_name"
        filter="letter"
        :lp-ignore="false"
      >
        <div class="body-text-x-large">
          {{ $t('kyc.modal.basic_info.first_name') }}
        </div>
      </CustomInput>

      <CustomInput
        v-model="form.surName"
        testId="sur_name"
        filter="letter"
        :lp-ignore="false"
      >
        <div class="body-text-x-large">
          {{ $t('kyc.modal.basic_info.last_name') }}
        </div>
      </CustomInput>

      <CustomInput
        v-model="form.phone"
        testId="phone"
        :lp-ignore="false"
        inputmode="numeric"
        mask="###-###-####"
      >
        <div class="body-text-x-large">
          {{ $t('kyc.modal.basic_info.phone') }}
        </div>
      </CustomInput>

      <div class="mb-s12 body-text-x-large">
        {{ $t('kyc.modal.basic_info.date_of_birth') }}
      </div>
      <Datepicker
        v-model="form.birthday"
        :editable="false"
        :default-value="minDate"
        :disabled-date="isDisabledDate"
        format="MM/DD/YYYY"
        value-type="date"
      />
    </div>

    <template slot="actions">
      <ButtonV2
        :inactive="isLoading || !isFormValid"
        testId="btn-continue"
        :label="$tc('continue')"
        submit
        wide
      />
    </template>
  </KycContainer>
</template>

<script>
import { DateTime } from 'luxon';

import { KycContainer } from '@/components/kyc';
import { ButtonV2, CustomInput, Datepicker } from '@/components/misc';

export default {
  name: 'KycBasicInfoLayout',
  components: {
    ButtonV2,
    CustomInput,
    Datepicker,
    KycContainer,
  },
  data() {
    return {
      form: {
        birthday: '',
        firstName: '',
        phone: '',
        surName: '',
      },
    };
  },
  props: {
    isLoading: Boolean,
    storedForm: Object,
  },
  async mounted() {
    this.updateDataWithStore();
  },
  computed: {
    birthdayDateTime() {
      return DateTime.fromJSDate(this.form.birthday);
    },

    isBirthdayValid() {
      return this.birthdayDateTime.isValid && this.birthdayDateTime.diff(this.minDate) < 0;
    },

    isFormValid() {
      const { firstName, surName } = this.form;

      return !!firstName && !!surName && this.isPhoneValid && this.isBirthdayValid;
    },

    isPhoneValid() {
      // Some countries have 10 and some have 11 digits in the phone number (excluding country code).
      // We need to revisit the phone masking in the future once we open for more countries, but this at least should work.
      // Product asked to keep phone to only US for now
      return !!this.form.phone && this.form.phone.length === 12;
    },

    minDate() {
      return DateTime.now().minus({ years: 18 });
    },
  },
  methods: {
    isDisabledDate(date) {
      return this.lodashGet(this.minDate.diff(DateTime.fromJSDate(date)), 'values.milliseconds', 0) <= 0;
    },

    updateDataWithStore() {
      const {
        birthday,
        firstName,
        phone,
        surName,
      } = this.storedForm;

      this.form = {
        ...this.form,
        birthday,
        firstName,
        phone,
        surName,
      };
    },
   
    next() {
      const { birthday, firstName, phone, surName } = this.form;

      this.$emit('onNext', {
        birthday,
        firstName,
        phone,
        surName,
      });
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
</style>
