<template>
  <KycBasicInfoLayout
    :key="JSON.stringify(storedForm)"
    @onNext="next"
    :isLoading="isLoading"
    :storedForm="storedForm"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import KycBasicInfoLayout from './layout';
import KYC_LITE_STEPS from '@/enums/KycLiteSteps';

export default {
  name: 'KycBasicInfo',
  components: {
    KycBasicInfoLayout,
  },

  data() {
    return {
      isLoading: false,
      storedForm: {
        firstName: '',
        surName: '',
        birthday: '',
        phone: '',
      }
    };
  },

  computed: {
    ...mapGetters('user', ['getKycLevelForAmplitude', 'getUserBasicInfo']),
  },

  async mounted() {
    this.setModalProps({
      hasPrevious: true,
      handlePrevious: this.previous,
      isClosable: false,
    });

    this.$store.dispatch('events/track', {
      event: 'KYC_BASIC_INFO_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude
      },
    });

    this.completeData();
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('user', [
        'updateBasicInfo',
        'updateKycLiteStep'
    ]),

    completeData() {
      const {
        birthday,
        firstName,
        phone,
        surName,
      } = this.getUserBasicInfo;

       this.storedForm = {
         ...this.storedForm,
         birthday: birthday ? new Date(birthday) : '',
         firstName,
         phone: phone.length > 1 ? phone.slice(2) : '',
         surName
       };

    },

    previous() {
      this.updateKycLiteStep(KYC_LITE_STEPS.COUNTRY);
    },

    async next({ firstName, surName, phone, birthday }) {
      this.isLoading = true;
      await this.updateBasicInfo({
        firstName,
        surName,
        phone,
        birthday,
      });
      this.isLoading = false;
    },
  }
};
</script>

<style scoped>
</style>
