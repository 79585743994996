<template>
  <div class="flex flex-col rounded-16 p-s16 border bg-background-active border-border-active-nav bg-opacity-50 border-opacity-50 body-text-medium gap-s16">
    <div class="flex items-center border-border border-b pb-s16 gap-s24">
      <slot name="icon" />
      <h4 class="subheadline-small">
        <slot name="title" />
      </h4>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'KycInfoContainer'
};
</script>
