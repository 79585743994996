<template>
  <component 
    :is="element" 
    :class="elementClasses"
    @submit.prevent="element === 'form' && $emit('submit')">

  <div class="flex flex-col h-full w-full">

    <h4 
      v-if="$slots.title"
      class="headline-small mb-s24 mt-s20">
      <slot name="title" />
    </h4>

    <slot />

  </div>

  <div
    v-if="$slots.actions"
    class="flex w-full mt-s64">
    <slot name="actions" />
  </div>

  </component>
</template>
<script>
export default {
  name: 'KycContainer',
  props: {
    element: {
      type: String,
      default: 'form'
    },
    padding: String,
    isMobile: Boolean
  },
  computed: {
    elementClasses() {
      return {
        'kyc-container--desktop': !this.isMobile,
        'kyc-container--mobile': this.isMobile,
        'flex flex-col justify-between items-center': true,
        'pb-s20 md:pb-s16': !this.padding,
        [this.padding]: this.padding
      };
    }
  }
};
</script>
<style scoped>
.kyc-container--desktop {
  width: 410px;
}
.kyc-container--mobile {
  height: 100%;
}
</style>
